<template>
  <div>
    <a-card v-if="demandesCount" title="Demandes ouvertures">
       <a-badge slot="extra" :count="demandesCount" 
       :number-style="{ backgroundColor: '#009688' }" />
  
      <p v-for="d in demandes" :key="d.id">
          Votre demande d'ouverture {{d.nom_fr}} est en 
          <etat-demandes-ouvertures-indicator  :etat="d.etat"/>.
      </p>
    </a-card>
    <empty-result v-else message="Vous n'avez aucune demande d'ouverture !"/>
  </div>
</template>
<script>
import EtatDemandesOuverturesIndicator from "@/components/common/EtatDemandesOuverturesIndicator";
import EmptyResult from "./EmptyResult";
import {  mapState } from "vuex";
export default {
  name: "DemandesOuverturesIndicator",
  components : {
      EtatDemandesOuverturesIndicator,
      EmptyResult
  },
    created() {
    this.fetchData();
  },
 
  computed: {
    ...mapState({
      demandes: (state) => state.demandes.demandes,
      user: (state) => state.adherent.adherent
    }),
    demandesCount() {
      return this.demandes?this.demandes.length : 0;
    },
  },
  methods: {
    fetchData() {
        this.$store.cache.dispatch("fetchAdherent").then(() => {
        this.$store.cache.dispatch("fetchDemandesByAdherent");
      });
    },
  },
};
</script>
<style scoped>
p{
    font-weight: 700;
}
</style>